<template>
    <div>
      <div class="fluid">
        <div class="jumbotron jumbotron-fluid bg-image fotoqla">
          <div class="container text-center">
            <h1 class="display-4 text-white">
              <strong>¡Con Andimar también es posible enviar dinero al extranjero!</strong>
            </h1>
            <p class="lead"></p>
            <div class="row">
              <div class="col-sm-6">
                <img src="../assets/logo2024.png" alt="Logo" width="50%">
              </div>
              <div class="col-sm-6">
                <img src="../assets/WesternUnion_PrimaryLockup_YellowWhite.png" alt="Logo" width="50%">
              </div>
            </div>
          </div>
          <div class="overlay"></div>
        </div>
      </div>
      <br>
      <section style="margin: 35px 35px 120px;">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <h5 class="card-header text-center"></h5>
              <div class="card-body">
                <h3 class="card-text text-center text-muted">Conoce las sucursales que se encuentran operativas para realizar este servicio</h3>
                <br>
                <div class="col-sm-12">
                  <!-- Nav tabs -->
                  <ul class="nav nav-tabs nav-pills" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active" data-bs-toggle="tab" href="#cjc">Calama</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-bs-toggle="tab" href="#anf">Antofagasta</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-bs-toggle="tab" href="#cpo">Copiapó</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-bs-toggle="tab" href="#scl"><strong>Santiago</strong> (Terminal San Borja)</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-bs-toggle="tab" href="#stgo"><strong>Santiago</strong> (Terminal Sur)</a>
                    </li>
                  </ul>
                  <!-- Tab panes -->
                  <div class="tab-content">
                    <div id="cjc" class="container tab-pane active"><br>
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="embed-responsive embed-responsive-16by9">
                            <iframe class="embed-responsive-item" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d562.6317672503932!2d-68.92877101030507!3d-22.450704119126186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x96ac09c06c0672c7%3A0xfb0f6892623aad5!2sTerminal%20de%20buses%20Calama!5e0!3m2!1ses-419!2scl!4v1713988861059!5m2!1ses-419!2scl" width="100%" height="350" style="border:0;" loading="lazy"></iframe>
                          </div>
                          <br>
                        </div>
                        <div class="col-sm-6">
                          <p class="display-6"><strong>Terminal de Buses Calama</strong></p>
                          <p><i class="fa fa-map-o" style="font-size:24px"></i> <strong>Granaderos 2963, Of 16</strong></p>
                          <p><i class="fa fa-clock-o" style="font-size:24px"></i> <strong>Horario: 8:00 Hrs. - 22:00 Hrs.</strong></p>
                        </div>
                      </div>
                    </div>
                    <div id="anf" class="container tab-pane fade"><br>
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="embed-responsive embed-responsive-16by9">
                            <iframe class="embed-responsive-item" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d533.8141373338955!2d-70.38919825433477!3d-23.618140013498685!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x96ae2b606178ad61%3A0x696bf09b0b8bb67d!2sTERMINAL%20ANTOFAGASTA!5e0!3m2!1ses-419!2scl!4v1713990745367!5m2!1ses-419!2scl" width="100%" height="350" style="border:0;" loading="lazy"></iframe>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <p class="display-6"><strong>Terminal de Buses Antofagasta</strong></p>
                          <p><i class="fa fa-map-o" style="font-size:24px"></i> <strong>Pedro Aguirre Cerda 5750, of. 13-13B</strong></p>
                          <p><i class="fa fa-clock-o" style="font-size:24px"></i> <strong>Horario: 8:00 Hrs. - 22:00 Hrs.</strong></p>
                          <p><i class="fa fa-phone-square" style="font-size:24px"></i> <strong><a href="tel:+56 55 260 14 89">+56 55 260 14 89</a></strong></p>
                        </div>
                      </div>
                    </div>
                    <div id="cpo" class="container tab-pane fade"><br>
                      <div class="row">
                        <div class="col-sm-6">
                            <div class="embed-responsive embed-responsive-16by9">
                                <iframe class="embed-responsive-item" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3543.183839015984!2d-70.33678142489393!3d-27.369973476377577!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x96980566a2bfcf83%3A0x36f1c0a671901d1c!2sTerminal%20Andimar%20vip%20Copiap%C3%B3!5e0!3m2!1ses-419!2scl!4v1713988441984!5m2!1ses-419!2scl" width="100%" height="350" style="border:0;" loading="lazy"></iframe>
                            </div>
                        </div>
                        <div class="col-sm-6">
                          <p class="display-6"><strong>Terminal Andimar Copiapó</strong></p>
                          <p><i class="fa fa-map-o" style="font-size:24px"></i> <strong>Colipí 114</strong></p>
                          <p><i class="fa fa-clock-o" style="font-size:24px"></i> <strong>Horario: 5:30 Hrs. - 23:30 Hrs.</strong></p>
                          <p><i class="fa fa-phone-square" style="font-size:24px"></i> <strong><a href="tel:+56 52 221 98 79">+56 52 221 98 79</a></strong></p>
                        </div>
                      </div>
                    </div>
                    <div id="scl" class="container tab-pane fade"><br>
                      <div class="row">
                        <div class="col-sm-6">
                            <div class="embed-responsive embed-responsive-16by9">
                                <iframe class="embed-responsive-item" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3328.8299737488924!2d-70.6798708!3d-33.453736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c4f9f0085325%3A0xaf3e1b1485d6d11b!2sTerminal%20San%20Borja!5e0!3m2!1ses-419!2scl!4v1716830892163!5m2!1ses-419!2scl" width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                        <div class="col-sm-6">
                          <p class="display-6"><strong>Terminal San Borja</strong></p>
                          <p><i class="fa fa-map-o" style="font-size:24px"></i> <strong>San Borja Nº184, of. 37</strong></p>
                          <p><i class="fa fa-clock-o" style="font-size:24px"></i> <strong>Horario: 5:30 Hrs. - 23:30 Hrs.</strong></p>
                          <p><i class="fa fa-phone-square" style="font-size:24px"></i> <strong><a href="tel:+56 2 276 48 902">+56 2 276 48 902</a></strong></p>
                        </div>
                      </div>
                    </div>
                    <div id="stgo" class="container tab-pane fade"><br>
                      <div class="row">
                        <div class="col-sm-6">
                            <div class="embed-responsive embed-responsive-16by9">                                
                                <iframe class="embed-responsive-item" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3328.796184688069!2d-70.6884901!3d-33.4546162!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c585a5649dc1%3A0xb2b5631694ac04e6!2sTerminal%20Sur%20Santiago%20de%20Chile!5e0!3m2!1ses-419!2scl!4v1716830844235!5m2!1ses-419!2scl" width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                        <div class="col-sm-6">
                          <p class="display-6"><strong>Terminal Sur</strong></p>
                          <p><i class="fa fa-map-o" style="font-size:24px"></i> <strong>Avda. Lib. Bernardo O. Nº3850, Of. 20-21, 147-148</strong></p>
                          <p><i class="fa fa-clock-o" style="font-size:24px"></i> <strong>Horario: Lunes a viernes: 4:30 Hrs. – 23:00 Hrs. Sábados y domingos: 5:30 Hrs. - 23:00 Hrs.</strong></p>
                          <p><i class="fa fa-phone-square" style="font-size:24px"></i> <strong><a href="tel:+56 2 2779 38 10">+56 2 2779 38 10</a></strong></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>     
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="clertic-footer bg-clertic text-white text-center p-3">
        <p>© 2024 Andimar</p>
      </footer>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CoverSection',
  };
  </script>
  
  <style scoped>
  .jumbotron {
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, .5);
    background-image: url(../assets/cajaswuu.webp);
    background-size: cover;
    background-position: top;
    background-position-y: -80px;
    background-repeat: no-repeat;
    position: relative;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .bg-clertic {
    background-color: #1e2580 !important;
  }
  </style>
  