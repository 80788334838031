<template>
  <div id="app">
    <Cover />
  </div>
</template>

<script>
import Cover from './components/wubyclertic.vue';

export default {
  name: 'App',
  components: {
    Cover
  }
};
</script>
